//文件生成&下载
<template>
  <div class="fileGeneration">
    <base-form
      :componentList="otherFile"
      :formAttrs="{
        model: fileFormData,
        labelWidth: '100px',
      }"
      :showBtns="false"
      class="formData"
      ref="otherFile"
    >
    </base-form>
     <!-- 文件预览 -->
    <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileFileSuffix" :count="fileCount" />
  </div>
</template>
<script>
import { otherFile } from '../utils/make-config'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import Storage from '@/utils/storage'
import PreView from '@/components/pre-view/pre-view.vue'

export default {
  props: {
    fileMakeData: Object
  },
  components: { BaseForm, PreView },
  data () {
    return {
      // generatefileId: '',
      fileData: {},
      fileCount: 0
    }
  },
  computed: {
    otherFile () {
      return otherFile(this)
    },
    fileFormData: {
      get () {
        return this.fileMakeData
      },
      set (val) {

      }
    }
  },
  watch: {
    fileFormData: {
      handler (val) {
        if (val.fileId) {
          this.$nextTick(() => {
            this.$refs.otherFile.clearValidate()
          })
        }
      },
      deep: true
    }
    // generatefileId (val) {
    //   if (val) {
    //     console.log('1')
    //     this.$set(this.fileFormData, 'fileId', val)
    //     this.$nextTick(() => {
    //       this.$refs.otherFile.clearValidate()
    //     })
    //   }
    // },
    // fileMakeData: {
    //   handler (val) {
    //     if (val.fileId) {
    //       this.generatefileId = val.fileId
    //     }
    //   },
    //   deep: true
    // }
  },
  methods: {
    // 删除文件
    delFile () {
      this.$set(this.fileFormData, 'fileId', '')
      this.$set(this.fileFormData, 'fileName', '')
      // this.generatefileId = ''
      this.$refs.otherFile.clearValidate()
    },
    // 文件预览
    previewShow () {
      if (this.fileFormData && this.fileFormData.fileId) {
        this.$set(this.fileData, 'fileId', this.fileFormData.fileId)
        this.$set(this.fileData, 'fileFileSuffix', this.fileFormData.fileName.substr(this.fileFormData.fileName.lastIndexOf('.') + 1, 4))
        this.fileCount++
      }
    },
    // 生成文件
    async generateFile () {
      if (!this.$parent.verificationAll()) {
        await this.$parent.getfn('generate')
      } else {
        return
      }
      const data = { businessId: this.$route.query.businessId, financeProductType: Storage.getLocal('admittanceInfo').financeProductType }
      financeAdmittanceApi.genarate(data).then(res => {
        if (res.data) {
          this.$set(this.fileFormData, 'fileName', res.data.fileName)
          this.$set(this.fileFormData, 'fileId', res.data.keyId)
          this.success('文件生成成功')
        } else {
          this.warning('文件生成失败')
        }
      })
    },
    // 校验
    valiFile () {
      let flag = false
      this.$refs.otherFile.validate(valid => {
        flag = valid
      })
      return flag
    }
  }
}
</script>
<style lang="scss" scoped>
.fileGeneration {
  padding: 20px 10px 50px;
}
</style>
