// 附件
<template>
  <div>
    <base-table
      :columns="accessoriesColumn"
      :tableAttrs="{
        data: accessoriesData,
        stripe: true,
      }"
      :isCaculateHeight="false"
      :showPage="false"
    >
     <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- 是否已提供 -->
      <template slot="isProvide" slot-scope="scope">
        <el-checkbox
          v-model="scope.row.isProvide"
          :true-label="'1'"
          :false-label="'0'"
          @change="checkboxchange(scope.row.isProvide, scope.$index)"
        ></el-checkbox>
      </template>
      <template slot="fileNum" slot-scope="scope">
        <el-input-number
        size="mini"
        :max=10
        :min=0
        v-model="scope.row.fileNum"
        ></el-input-number>
      </template>
      <template slot="noReasonProvided" slot-scope="scope">
        <el-input
        v-if="scope.row.isProvide==='0'"
        v-model="scope.row.noReasonProvided"
        maxlength="250"
        ></el-input>
        <div v-else>/</div>
      </template>
      <!-- 追加文件 -->
      <template slot="action" slot-scope="scope">
        <icon-button
          @click="appendFile(scope.row, 2, scope.$index)"
          content="追加文件"
          icon="iconfont iconzhuijia"
        />
      </template>
      <!-- 文件名称 -->
      <template slot="fileListDetailVOS" slot-scope="scope">
        <!-- 文件列按钮组 -->
        <file-button-group
          v-for="(item, index) in scope.row.fileListDetailVOS"
          :key="index"
          :item="item"
          :index="index"
          :scope="scope"
          :isSign="scope.row.isSign"
          @previewShow="openPreview"
          @Upload="Upload"
          @deleteTemplate="deleteTemplate"
          @dowload='dowload'
        />
      </template>
    </base-table>
    <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileFileSuffix" :count="count" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import baseTable from '@/components/common/table/base-table/base-table.vue'
import { accessoriesTable } from '../utils/make-config'
import { fileListApi } from '@/api/businessApi'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import FileButtonGroup from './file-button-group.vue'
import { fileAPi, downAPi } from '@/api/fileApi'
import { downFile } from '@/utils/devUtils'

import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
export default {
  props: {

  },
  components: { baseTable, IconButton, FileButtonGroup, PreView },
  data () {
    return {
      count: 0,
      visible: false,
      fileData: { fileId: '', fileFileSuffix: '' },
      accessoriesData: [], // 备案条款数据
      businessId: ''
    }
  },
  computed: {
    accessoriesColumn () {
      return accessoriesTable(this)
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  watch: {
    businessId (val) {
      if (val) {
        this.getTableData()
      }
    }
  },
  methods: {
    // 初始化数据
    getTableData () {
      financeAdmittanceApi.getAdmittanceFile({ businessId: this.businessId }).then(res => {
        res.data.forEach(item => {
          this.$set(item, 'fileNum', item.fileNum * 1)
        })
        this.accessoriesData = res.data
      })
    },
    addInfo () {},
    checkboxchange (data, index) {
      if (data === '1') { // 选中清空未收集原因
        this.$set(this.accessoriesData[index], 'noReasonProvided', '')
      }
    },
    // 预览
    openPreview (data) {
      if (data) {
        this.fileData = data
        this.count++
      }
      // this.fileData = data
    },
    // 文件上传
    async Upload (param, row, index) {
      // const arr = param.file.name.split('.')
      // const suffix = arr[arr.length - 1]
      // const lite = ['doc', 'docx', 'xlsx', 'xls', 'pdf', 'png', 'jpg', 'jpeg', 'bmp']
      // const flag = lite.some((val) => {
      //   return suffix === val
      // })
      // if (!flag) {
      //   this.warning('请上传doc,docx,xlsx,xls,pdf,png,jpg,jpeg,bmp格式文件')
      //   return
      // }
      const res = await this.UploadFile(param)
      if (!res) {
        return
      }
      console.log(param)
      console.log(row, 'row')
      const data = [{
        businessId: row.businessId,
        fileId: res.keyId,
        fileListKeyId: row.keyId,
        fileName: res.fileName,
        keyId: row.keyIdF,
        documentType: '1002',
        documentName: row.documentName
      }]
      fileListApi.saveFinFileListDetails(data).then((res) => {
        if (res.data) {
          this.success('文件上传成功')
          this.getTableData()
        }
      })
    },
    async UploadFile (param) {
      const EmojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g

      if (EmojiReg.test(param.file.name)) {
        return this.warning('文件名不能存在Emoji')
      }
      const maxSize = 1024 * 10
      const fileSize = Math.round(param.file.size / 1024)
      if (fileSize - maxSize >= 0) {
        return this.warning('文件需小于10M')
      }

      const formData = new FormData()
      formData.append('file', param.file)
      formData.append('belongCode', 'TEMPLATE')
      const res = await fileAPi.file(formData).then((res) => {
        if (res.data) {
          return res.data
        }
      })
      return res
    },
    // 追加文件
    appendFile (row, type, index) {
      console.log(row, 'row')
      const [data] = row.fileListDetailVOS ? row.fileListDetailVOS.filter((item) => item.addFile) : [null]
      if (data) {
        return this.warning('请先上传文件')
      }
      if (row.fileListDetailVOS.length > 9) {
        return this.warning('最多上传10份文件')
      }
      if (!row.fileListDetailVOS) {
        this.$set(row, 'fileListDetailVOS', [])
      }
      row.fileListDetailVOS.push({ fileId: '0', fileName: '', addFile: true })
    },
    // 删除文件
    deleteTemplate (row, index, i) {
      // 没有上传文件的直接删除
      if (row.fileId === '0') {
        this.accessoriesData[index].fileListDetailVOS.splice(i, 1)
        return
      }
      this.$confirm('是否删除文件?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          // 调用接口删除
          fileListApi.deleteFileElement([row.fileListDetailVOS[i].keyId]).then(res => {
            if (res.data) {
              this.success('删除成功')
              this.accessoriesData[index].fileListDetailVOS.splice(i, 1)
              this.getTableData()
            } else {
              this.error('删除失败')
            }
          })
        })
        .catch(() => {})
    },
    // 重新上传文件
    updataFile (file) {
      const files = file.target.files[0]
      const formData = new FormData()
      formData.append('file', files)
      this.api
        .UploadLmplementFile(formData)
        .then((res) => {
          this.arr.forEach((item) => {
            if (res.data.fileName === item.documentName) {
              item.fileId = res.data
            }
          })
          this.$message.success('文件上传成功')
        })
        .catch((res) => {
          this.$message.error('文件上传失败')
        })
    },
    // 文件下载
    dowload (data) {
      console.log(data, 'data')
      const fileId = data.currentFileId || data.fileId
      if (fileId) {
        const params = { keyId: fileId }
        downAPi
          .download(params)
          .then((res) => {
            downFile(res)
          })
          .catch((e) => {
            this.error(e)
          })
      } else {
        this.error('抱歉暂无资源')
      }
    }

  }
}
</script>
<style lang="scss" scoped>
</style>
