// 稳享付----准入文件制作
<template>
  <div class="accessMake">
    <backtitle></backtitle>
    <div class="mainContent" id="mainContent">
      <div data-title="融资申请信息" class="modelBox">
        <text-title index='01' label="融资申请信息"></text-title>
        <financing-application-info :financingApplicationData='financingApplicationData'  ref="financingApplicationData"></financing-application-info>
      </div>
      <div data-title="标的资产" class="modelBox">
        <text-title index='02' label="标的资产"></text-title>
        <asset-information ref="assetInformation" @changePledgeRate="changePledgeRate" :showRequired='showRequired'></asset-information>
      </div>
      <!-- <div data-title="备案条款" class="modelBox">
        <text-title index='03' label="备案条款"></text-title>
        <filing-terms ref="filingTerms"></filing-terms>
      </div> -->
      <div data-title="经营及合作情况" class="modelBox">
        <text-title index='03' label="经营及合作情况"></text-title>
       <div style="padding:10px">
         <el-input type="textarea" placeholder="请输入" v-model="tipsDescription" maxlength="2000" show-word-limit rows='5'></el-input>
       </div>
      </div>
      <div data-title="附件" class="modelBox">
        <text-title index='04' label="附件"></text-title>
        <accessories ref="accessories"></accessories>
      </div>
      <div data-title="业务经理意见" class="modelBox">
        <text-title index='05' label="业务经理意见"></text-title>
        <!-- <investigation-arrangement :expectDueDiligence='expectDueDiligence' ref="expectDueDiligence"></investigation-arrangement> -->
        <div style="padding:10px">
          <el-input type="textarea" placeholder="请输入" v-model="reviewOpinion" maxlength="2000" show-word-limit rows='5'></el-input>
        </div>
      </div>
      <div data-title="文件生成与下载" class="modelBox">
        <text-title index='06' label="文件生成与下载"></text-title>
        <file-generation :fileMakeData='fileMakeData' ref="fileMakeData"></file-generation>
      </div>
    </div>
    <div class="footer">
      <base-button label="保 存" @click="saveData('save')"></base-button>
      <base-button label="提 交" @click="saveData('submit')"></base-button>
      <base-button label="关 闭" type="default" @click="close"></base-button>
    </div>
  </div>
</template>
<script>
import TextTitle from '@/components/packages/text-title/text-title.vue'
import Backtitle from '../../components/backtitle.vue'
import baseButton from '@/components/common/button/base-button/base-button.vue'
// import BaseForm from '@/components/common/base-form/base-form'
import FinancingApplicationInfo from '../components/financing-application-info.vue' // 融资申请
import AssetInformation from '../components/asset-information.vue' // 标的资产
// import FilingTerms from '../components/filing-terms.vue' // 备案信息
import Accessories from '../components/accessories.vue' // 附件
// import InvestigationArrangement from '../components/investigation-arrangement.vue' // 尽调安排
import fileGeneration from '../components/file-generation-and-download.vue'
import { financeAdmittanceApi } from '@/api/financeAdmittanceApi'
import { PSBC_KEY_ID } from '@/config/constant'

export default {
  components: {
    TextTitle,
    Backtitle,
    baseButton,
    AssetInformation,
    FinancingApplicationInfo,
    // FilingTerms,
    Accessories,
    // InvestigationArrangement,
    fileGeneration
  },
  props: {
    baseData: Object
  },
  data () {
    return {
      showRequired: false,
      financingApplicationData: {

      }, // 融资申请信息
      tipsDescription: '', // 提示
      expectDueDiligence: {}, // 尽调数据
      fileMakeData: {}, // 文件制作名称
      passArr: [],
      savePassArr: [], // 所有校验信息
      reviewOpinion: '' // 业务经理意见
    }
  },
  created () {
    this.businessId = this.$route.query.businessId
  },
  watch: {
    financingApplicationData: {
      handler (val) {
        // 资金方为邮储银行时 付款单必填
        if (val.capitalSideId === PSBC_KEY_ID) {
          this.showRequired = true
        } else {
          this.showRequired = false
        }
      },
      deep: true
    },
    baseData: {
      handler (val) {
        if (val) {
          // 申请信息
          this.financingApplicationData = JSON.parse(JSON.stringify(val))
          // 提示
          this.tipsDescription = JSON.parse(JSON.stringify(val?.tips))
          this.reviewOpinion = JSON.parse(JSON.stringify(val?.reviewOpinion))
          const { expectDueDiligenceAddress, expectDueDiligenceDate, expectMeetingTime } = { ...val }
          // 尽调安排
          this.expectDueDiligence = { expectDueDiligenceAddress, expectDueDiligenceDate, expectMeetingTime }
          this.expectDueDiligence = JSON.parse(JSON.stringify(this.expectDueDiligence))
          // 生成文件
          this.fileMakeData = val.fileListInfo ? JSON.parse(JSON.stringify(val.fileListInfo)) : {}
        }
      }
    }
  },
  computed: {
    subArr () {
      return new Map([['save', '保存成功'], ['submit', '提交成功']])
    },
    errArr () {
      return new Map([['save', '保存失败'], ['submit', '提交失败']])
    }
  },
  methods: {
    // 校验所有信息
    verificationAll (subType) {
      this.savePassArr = []
      // 融资申请信息
      if (!this.$refs.financingApplicationData.validateFinancingApplication()) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善融资申请信息', module: 0 })
      }
      this.vailAssetInfoTable()
      // 融资申请中融资金额等于资产信息中对应融资额之和
      // let relLoanAmountAll = 0
      // if (this.$refs.assetInformation.assetInfoData.length > 0) {
      //   this.$refs.assetInformation.assetInfoData.forEach(item => {
      //     relLoanAmountAll += parseFloat(item.relLoanAmount)
      //   })
      //   if (parseFloat(this.$refs.financingApplicationData.currentData.applyAmount) !== relLoanAmountAll) {
      //     this.savePassArr.push({ isPass: false, warningTip: '融资申请中融资金额=资产信息中对应融资额之和', module: 0 })
      //   }
      // }
      // this.vailProjectInfo()
      this.vailAccessories()
      // 尽调安排
      // if (!this.$refs.expectDueDiligence.valiExpectDueDiligence()) {
      //   this.savePassArr.push({ isPass: false, warningTip: '请完善尽调安排', module: 5 })
      // }
      if (subType === 'submit') {
        // 文件生成
        // console.log(this.$refs.fileMakeData.fileMakeData, this.$refs.fileMakeData.valiFile(), '-=-=-=-=-=-=-=-=-=')
        if (!this.$refs.fileMakeData.valiFile()) {
          this.savePassArr.push({ isPass: false, warningTip: '请生成或上传文件', module: 7 })
        }
      }
      const tip = this.savePassArr.find(item => item.isPass === false)
      console.log(tip, '发现的节点')
      if (tip) {
        this.warning(tip.warningTip)
        this.$parent.scrollFool(tip.module)
      } else {
        console.log('提交成功')
      }
      return tip
    },
    // 标的资产校验
    vailAssetInfoTable () {
      let pass = true
      if (this.$refs.assetInformation.assetInfoData.length > 0) {
        this.$refs.assetInformation.assetInfoData.forEach(item => {
          if (!item.finContractId || !item.finContractName || !item.pledgeValue || !item.relLoanAmount || (this.showRequired && !item.easNo)) {
            pass = false
          }
        })
      } else {
        pass = false
      }
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善标的资产资产信息', module: 1 })
      }
    },
    // 标的资产项目信息
    vailProjectInfo () {
      let pass = true
      if (this.$refs.assetInformation.projectInfoData.length > 0) {
        this.$refs.assetInformation.projectInfoData.forEach(item => {
          if (!item.shareholdingRatio || !item.controllingEntity) {
            pass = false
          }
        })
      } else {
        pass = false
      }
      if (!pass) {
        this.savePassArr.push({ isPass: false, warningTip: '请完善标的资产项目信息', module: 1 })
      }
    },
    // 计算折扣率
    changePledgeRate (data) {
      let pledgeValue = 0
      let reloanAmount = 0
      if (Array.isArray(data) && data.length !== 0) {
        data.forEach(item => {
          pledgeValue += Number(item.pledgeValue) || 0
          reloanAmount += Number(item.relLoanAmount) || 0
        })
      }
      let pledgeRate = ((reloanAmount / pledgeValue) * 100).toFixed(2)
      if (isNaN(pledgeRate) || pledgeRate === 'Infinity') pledgeRate = 0
      this.$set(this.financingApplicationData, 'totalPledgeRate', pledgeRate)
    },
    // 附件校验
    vailAccessories () {
      // let pass = true
      // const arr = this.$refs.accessories.accessoriesData
      // if (arr.length > 0) {
      //   for (const obj of arr) {
      //     if (obj.isProvide !== '1') {
      //       if (!obj.noReasonProvided) {
      //         this.savePassArr.push({ isPass: false, warningTip: '请输入未收集原因', module: 6 })
      //       }
      //     }
      //   }
      // } else {
      //   pass = false
      // }
      // if (!pass) {
      //   this.savePassArr.push({ isPass: false, warningTip: '请选择附件', module: 6 })
      // }

    },
    // 设置基本信息质押率
    chanegPledgeRate (data, isComplete) {
      if (!isComplete) {
        data = data === 'NaN' ? '' : data
        this.$set(this.financingApplicationData, 'totalPledgeRate', data)
      } else {
        this.$set(this.financingApplicationData, 'totalPledgeRate', '')
      }
    },
    // 调用方法保存或者提交
    async getfn (subType) {
      this.passAr = []
      await this.saveBaseInfo(subType)
      await this.saveOrUpdateContract(subType)
      // await this.saveFilingTerms(subType)
      await this.saveAdmittanceFile(subType)
      // 如果是生成文件,校验后不提示
      if (subType === 'generate') {
        return
      }
      // 提示
      const isPass = this.passArr.every(item => item === true)
      if (isPass) {
        this.success(this.subArr.get(subType))
        if (subType === 'submit') {
          this.$router.back()
        }
      } else {
        this.warning(this.errArr.get(subType))
      }
    },
    // 保存（save）提交（submit）文件生成（generate）
    async  saveData (subType) {
      this.savePassArr = []
      if (subType === 'submit') {
        if (!this.verificationAll(subType)) {
          this.getfn(subType)
        }
      } else if (subType === 'save') {
        this.getfn(subType)
      }
    },
    // 备案条款保存 submitType:0 保存 1生成
    async  saveFilingTerms (subType) {
      const data = { data: this.$refs.filingTerms.filingTermsData, businessId: this.businessId }
      let res = {}
      if (subType === 'save') {
        res = await financeAdmittanceApi.saveFilingTerms(data)
      } else if (subType === 'submit') {
        res = await financeAdmittanceApi.submitFilingTerms(data)
      } else if (subType === 'generate') {
        res = await financeAdmittanceApi.submitFilingTerms(data)
      }
      console.log(res.data, '备案条款保存')
      return this.passArr.push(res.data)
    },
    // 保存附件信息
    async  saveAdmittanceFile (subType) {
      const data = { finFileListInfos: this.$refs.accessories.accessoriesData, saveFlag: subType === 'save' ? '0' : '1' }
      const res = await financeAdmittanceApi.saveAdmittanceFile(data)
      console.log(res.data, '保存附件信息')
      return this.passArr.push(res.data)
    },
    // 保存基本信息
    async  saveBaseInfo (subType) {
      console.log(this.$refs.fileMakeData.fileFormData, '生成文件信息')
      const data = { ...this.$refs.financingApplicationData.currentData, fileListInfo: this.$refs.fileMakeData.fileFormData }
      data.tips = this.tipsDescription
      data.reviewOpinion = this.reviewOpinion
      // const { expectDueDiligenceAddress, expectDueDiligenceDate, expectMeetingTime } = { ...this.$refs.expectDueDiligence.expectDueDiligence }
      // data.expectDueDiligenceAddress = expectDueDiligenceAddress
      // data.expectDueDiligenceDate = expectDueDiligenceDate
      // data.expectMeetingTime = expectMeetingTime
      delete data.businessId
      let res = {}
      if (subType === 'save') {
        res = await financeAdmittanceApi.saveAdmittance(data)
      } else if (subType === 'submit') {
        data.submitType = '0'
        res = await financeAdmittanceApi.submitAdmittance(data)
      } else if (subType === 'generate') {
        data.submitType = '1'
        res = await financeAdmittanceApi.submitAdmittance(data)
      }
      console.log(res.data, '保存基本信息')
      return this.passArr.push(res.data)
    },
    // 保存标的资产信息
    async saveOrUpdateContract (subType) {
      const data = { businessId: this.businessId, finContractInfoList: this.$refs.assetInformation.assetInfoData, finProjectInfoList: this.$refs.assetInformation.projectInfoData }
      let res = {}
      if (subType === 'save') {
        res = await financeAdmittanceApi.saveOrUpdateContract(data)
      } else if (subType === 'submit') {
        data.submitType = '0'
        res = await financeAdmittanceApi.submitOrUpdateContract(data)
      } else if (subType === 'generate') {
        data.submitType = '1'
        res = await financeAdmittanceApi.submitOrUpdateContract(data)
      }
      console.log(res.data, '保存标的资产信息')
      return this.passArr.push(res.data)
    },

    close () {
      this.$router.back()
    }
  }
}
</script>
<style lang="scss" scoped>
.accessMake {
  padding: 0px 10px;
  height: 100%;
  background: #f9f9f6;
  .mainContent {
    background: #fff;
    height: calc(100% - 105px);
    overflow-y: scroll;
  }
  .footer {
    text-align: center;
    margin: 0px 0 10px;
    padding: 14px 0;
    box-shadow: 0px 0px 13px 2px rgba(#4A7CF0, 0.2);
    background: #ffffff;
  }
}
.line{
  width: 100%;
  height: 10px;
  background-color: #f9f9f6;
}
</style>
