<template>
  <!-- 模板文件操作按钮组 -->
  <div class="fileButtonGroup">
    <!-- 文件名称 -->
    <div class="name">
      <el-tooltip
        effect="dark"
        placement="top"
        :enterable="false"
        :hide-after='3000'
        :content="item.fileName">
        <span >{{item.fileName}}</span>
      </el-tooltip>
    </div>
    <!-- 操作按钮 -->
    <span class="content">
      <icon-button
        content="预览"
        icon="iconfont iconyulan"
        @click="previewShow(item)"
        v-if="item.fileId !== '0'"
      />

      <!-- 上传文件 -->
      <el-tooltip
        class=""
        effect="dark"
        :enterable="false"
        content="上传"
        placement="top"
        v-if="item.fileId === '0'"
      >
        <base-button
          type="text"
          label=""
          icon="iconfont iconshangchuan"
          btnType="upload"
          accept=".doc,.docx,.xlsx,.xls,.pdf,.png,.jpg,.jpeg,.bmp"
          style="margin: 0px 10px"
          action="#"
          :http-request="function (url) {
              return Upload(url, item, scope.$index);
            }
          "
        />
      </el-tooltip>
      <!-- 下载 -->
      <icon-button
        content="下载"
        icon="iconfont iconxiazai"
        @click="dowload(item)"
        v-if="item.fileId !== '0'"
      />
      <!-- 删除 -->
      <icon-button
        @click="deleteTemplate(item, scope.$index, index)"
        content="删除文件"
        icon="iconfont iconshanchu2"
      />
    </span>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
export default {
  components: { IconButton, BaseButton },
  props: {
    scope: {},
    item: {},
    index: Number,
    fileIndex: Number,
    isSign: String,
    suppliersSealType: String
  },
  data () {
    return {}
  },
  mounted () {
    console.log(this.suppliersSealType === '电子章')
  },
  methods: {
    // 上传按钮显示条件
    showButton (item) {
      if (item.addFile) {
        return true
      }
      if (this.suppliersSealType === '电子章') {
        return this.isSign === '0'
      } else {
        return true
      }
    },
    // 预览
    previewShow (row) {
      this.$emit('previewShow', row)
    },
    // 文件上传
    Upload (param, row, childIndex) {
      this.$emit('Upload', param, { ...this.scope.row, ...row, keyId: this.scope.row.keyId, keyIdF: row.keyId }, childIndex, this.index) // keyIdF 文件项KeyId
    },
    // 文件删除
    deleteTemplate (row, type, index, i) {
      this.$emit('deleteTemplate', { ...this.scope.row, ...row, keyId: this.scope.row.keyId, keyIdF: row.keyId }, type, index, i)
    },
    // 文件下载
    dowload (row) {
      this.$emit('dowload', row)
    }
  }
}
</script>
<style lang="scss" scoped>
.fileButtonGroup {
  display: flex;
  align-items: center;
  .name {
    width: calc(100% - 106px);
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    text-align: left;
  }
  .content {
    width: 106px;
  }
}
</style>
