// 融资申请信息
<template>
  <div>
        <base-form
          :componentList="financingApplicationFrom"
          :showBtns="false"
          :formAttrs="{
            model: currentData,
            labelWidth: '150px',
          }"
          class="formStyle"
          ref="formDataList"
        />
  </div>
</template>
<script>
import { financingApplicationConfig } from '../utils/make-config'
import BaseForm from '@/components/common/base-form/base-form'
// import { financeAdmittanceApi } from '@/api/fichangeCostPriceanceAdmittanceApi'
// import { fundManagementApi } from '@/api/fundManagementApi'
import { getDictLists } from '@/filters/fromDict'
import { parameterApi } from '@/api/parameterApi'

export default {
  props: {
    financingApplicationData: Object
  },
  components: { BaseForm },
  data () {
    return {
      providertData: [],
      typelist: [], // 资金方下拉
      currentData: {}, // 当前表单数据
      hiddenServiceRate: true
    }
  },
  computed: {

    financingApplicationFrom () {
      return financingApplicationConfig(this)
    },
    // 合作类型字典
    cooperationType () {
      return getDictLists('COMPANY_COOPERATION_TYPE').map(item => {
        return { value: item.dictId, label: item.dictName }
      })
    }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    this.providertInfo()
    // 仅监听一次异步值
    const unwatch = this.$watch('financingApplicationData', function (newValue, oldValue) {
      console.log(newValue, oldValue, 'financingApplicationData')
      if (newValue) {
        newValue.cooperationType = newValue.cooperationType ? newValue.cooperationType : newValue.cooperationType = 'ON_LOAN' // 默认选中第一个
        newValue.totalPledgeRate = (newValue.totalPledgeRate || parseFloat(newValue.totalPledgeRate) === 0) ? newValue.totalPledgeRate : 0
        this.currentData = newValue
        this.$nextTick(() => {
          this.$refs.formDataList.clearValidate()
        })
      }
      unwatch()
    })
  },

  methods: {

    changeFinanceTermUnit () {
      this.$refs.formDataList.validateField('financeTerm')
    },
    // 校验
    validateFinancingApplication () {
      let flag = false
      this.$refs.formDataList.validate(valid => {
        flag = valid
      })
      return flag
    },
    changeValidTimeUnit (data) {
      console.log(data, 'data')
      this.$set(this.financingApplicationData, 'financeTermUnit', data)
    },
    changeCapitalSide (data) {
      console.log(data)
      if (data) {
        const [obj] = this.providertData.filter(item => item.keyId === data)
        console.log(obj)
        this.$set(this.financingApplicationData, 'capitalSideName', obj.capitalSideName)
        this.$set(this.financingApplicationData, 'capitalSideType', obj.capitalSideType)
        this.$set(this.financingApplicationData, 'productName', obj.productName)
        this.$set(this.financingApplicationData, 'capitalSideProductId', obj.capitalSideProductId)
        this.$set(this.financingApplicationData, 'capitalSideId', obj.keyId)
        this.$set(this.financingApplicationData, 'capitalSideProductName', obj.productName)
        if (obj.capitalSideType === '64') {
          this.hiddenServiceRate = true
          this.$set(this.financingApplicationData, 'serviceRate', 0)
        } else {
          this.hiddenServiceRate = false
        }
        if (obj.capitalSideProductId !== '2112020942065230001') {
          this.$set(this.financingApplicationData, 'costPrice', obj.productRate)
          this.changeCostPrice(obj.productRate)
        } else {
          this.$set(this.financingApplicationData, 'costPrice', '')
        }
      } else {
        this.hiddenServiceRate = true
        this.$set(this.financingApplicationData, 'capitalSideName', '')
        this.$set(this.financingApplicationData, 'capitalSideType', '')
        this.$set(this.financingApplicationData, 'productName', '')
        this.$set(this.financingApplicationData, 'capitalSideProductId', '')
        this.$set(this.financingApplicationData, 'capitalSideId', '')
        this.$set(this.financingApplicationData, 'capitalSideProductName', '')
        this.$set(this.financingApplicationData, 'costPrice', '')
      }
    },
    // 获取资金方类型
    // 获取资金方信息
    providertInfo () {
      const params = {
        replyStatus: 1 // 审核状态
      }
      parameterApi.getFundsprovidertInfo(params)
        .then(res => {
          if (res.data) {
            this.providertData = res.data
            this.providertData.forEach(ele => {
              this.$set(ele, 'capitalAndProductName', ele.capitalSideName + '-' + ele.productName)
            })
          }
        })
    },
    // // 融资利率
    changeFinanceRate (val) {
      let data = ''
      data = val * 1 - this.currentData.costPrice * 1
      if (data < 0 || data === 0) {
        if (!this.hiddenServiceRate) this.warning('服务费率应大于0, 服务费率 = 融资利率 - 成本价格')
        this.$set(this.currentData, 'serviceRate', '')
        this.$set(this.currentData, 'financeRate', '')
        return
      }
      this.$set(this.currentData, 'serviceRate', data ? data.toFixed(2) : '')
    },
    // 成本价格
    // changeCostPrice (val) {
    //   let data = ''
    //   data = this.currentData.financeRate * 1 - val * 1
    //   if (data < 0 || data === 0) {
    //     this.warning('服务费率应大于0, 服务费率 = 融资利率 - 成本价格')
    //     this.$set(this.currentData, 'serviceRate', '')
    //     this.$set(this.currentData, 'costPrice', '')
    //     return
    //   }
    //   this.$set(this.currentData, 'serviceRate', data ? data.toFixed(2) : '')
    // }

    // 成本价格
    changeCostPrice (val) {
      let data = ''
      data = this.currentData.financeRate * 1 - val * 1
      if (data < 0 || data === 0) {
        if (!this.hiddenServiceRate) this.warning('服务费率应大于0, 服务费率 = 融资利率 - 成本价格')
        this.$set(this.currentData, 'serviceRate', '')
        this.$set(this.currentData, 'financeRate', '')
        return
      }
      this.$set(this.currentData, 'serviceRate', data ? data.toFixed(2) : '')
    }

    // 计算服务菲费率
    // countServiceRate (financeRate, costPrice) {
    //   let data = ''
    //   if (financeRate && costPrice) {
    //     data = financeRate - costPrice
    //   }
    //   if (data < 0) {
    //     this.warning('服务费率应大于等同0, 服务费率 = 融资利率 - 成本价格')
    //     this.$set(this.currentData, 'serviceRate', '')
    //     return
    //   }
    //   this.$set(this.currentData, 'serviceRate', (data || data === 0) ? data.toFixed(2) : '')
    // }
  }
}
</script>
